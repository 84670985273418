'use strict';

module.exports = {
    change: function () {
        var urlParam = function(url, name){
            var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
            if (results==null) {
                return null;
            }
            return decodeURI(results[1]) || 0;
        }
        $('.ranking-select').prop("selectedIndex", 0);
        $('body').on('change', '.ranking-select', function (e) {
            let $rankingContainer = $('.ranking_container');
            $rankingContainer.empty();
            $.spinner().start();
            $('.ranking-select').attr('disabled', true);
            $.ajax({
                url: e.currentTarget.value,
                method: 'GET',
                success: function (data) {
                    $('.ranking-select').attr('disabled', false);
                    $rankingContainer.html(data.html);
                    $.spinner().stop();
                },
                error: function (err) {
                    $('.ranking-select').attr('disabled', false);
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                    $.spinner().stop();
                },
            });

            var anchorTarget = urlParam(e.currentTarget.value, "cgid");
            var rankingShowMoreUrl =  $("#ranking-show-more").attr("href");
            $("#ranking-show-more").attr("href", rankingShowMoreUrl.split('#')[0] + "#" + anchorTarget);
        });
    },
};
